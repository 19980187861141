.button {
  border: none;
  background: transparent;
  color: white;
  padding: 0.5rem 0.8rem;
  align-self: stretch;
  transition: background-color 0.2s;
  outline: none !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
