.MainApp {
  #trix-editor-top-level {
    padding-bottom: 0;
    position: static !important;
  }

  trix-toolbar {
    position: absolute;
    bottom: 2px;
    left: 1px;
    background-color: #ffffff;

    .trix-button--icon-strike,
    .trix-button--icon-heading-1,
    .trix-button--icon-quote,
    .trix-button-group--history-tools,
    .trix-button-group--file-tools {
      display: none !important;
    }

    .trix-button-row {
      .trix-button-group {
        margin-bottom: 0;
        border-radius: 0;
        border: none;
      }

      .trix-button-group--text-tools {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .trix-button-group--block-tools {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .trix-dialog--link {
      border: none;
      min-width: 320px;
    }
  }

  trix-editor {
    overflow: auto;
    border: none;
    overflow: auto;
  }
}
