@import "/src/styles/variables";

.root :global {
  .modal-content {
    border-radius: $sharp-border-radius;
    border: 3px solid $gray-border-color;
    padding: 1rem 1rem 0 1rem;
  }

  .modal-header {
    border-bottom: none;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
  }

  .modal-header .close {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .modal-body {
    padding-right: 2rem;
  }

  .btn-outline-secondary {
    border: 1px solid $brand-primary;
    border-radius: $sharp-border-radius;
    color: $brand-primary;
    font-weight: 600;
    min-height: calc(1.5em + 1.4rem);
    padding: 0.7rem 0.75rem;
    text-transform: uppercase;
    margin: 1rem 0 1rem 0;

    &:hover {
      background: linear-gradient(to right, #24c0ea 0%, #0192ca 100%);
    }
  }
}

.backdrop {
  background-color: $modal-backdrop-background !important;
  opacity: 0.95 !important;
  filter: none !important;
}

.subHeader {
  font-size: 16px;
  font-weight: 400;
  padding: 1rem 1rem 0 1rem;
}
