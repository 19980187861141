.user-components-Learn-Notes-module-root {
  .ql-editor.preview {
    padding: 12px 0 !important;
  }

  .ql-editor ol {
    padding-left: 1em !important;
  }

  .ql-editor li {
    margin-left: 0 !important;
  }
}

.ql-editor {
  tab-size: 1 !important;
}
