@import "./variables";

:root {
  // Have to override default z-index to show notifications over chat window
  --toastify-z-index: 9999999999;
}

.Toastify__toast {
  border-radius: $sharp-border-radius;
}

// The styles that were here have been deprecated in the update to react-toastify 8.2.0
