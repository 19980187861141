.p-datatable-table td {
  font-size: 14px;
  line-height: normal;
}

.p-datatable-table th {
  font-size: 14px;
  line-height: normal;
}

ol,
ul {
  padding-left: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #007bff;
}
