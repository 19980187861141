@import "./variables";

// rewrite of bootstrap components

// Remove underline caused by Bootstrap 5 update
.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

// in case you decide to use one of, apply brand colors to them
// .bg-primary,
// .btn-primary,
// .badge-primary,
// .alert-primar

@mixin btn-linear-gradient($from, $to) {
  background: linear-gradient(90deg, $from, $to);

  &:hover {
    background: linear-gradient(90deg, darken($from, 5%), darken($to, 5%));
  }
}

.btn-danger {
  background-color: $brand-error;
}

.btn-success {
  @include btn-linear-gradient($brand-success-light, $brand-success);
}

.progress-bar.bg-success {
  background: linear-gradient(90deg, $brand-success-light, $brand-success);
}

// Overrides from bootstrap upgrade to 5.2.3
.form-check {
  padding-left: 1.25rem;
  margin-bottom: 0 !important;
}

label {
  margin-bottom: 0 !important;
  padding: 4px;
}

.checkbox-secondary.form-switch {
  padding-left: 0 !important;
}

.form-check-input {
  margin-top: 0.35em !important;
  border: solid $sj-gray-color 1.25px;
}

.form-control {
  border-radius: $sharp-border-radius;
}
