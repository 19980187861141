@import "../styles/variables";

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  &.light {
    color: #fff;
  }

  &.withoutText {
    margin-bottom: 0;
  }

  &.centered {
    height: calc(100vh - $header-height);
  }
}

.border {
  border-color: $modal-font-color;
  border-right-color: transparent;
  width: 25px;
  height: 25px;

  &.light {
    border-color: #fff;
    border-right-color: transparent;
  }
}
