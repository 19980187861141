.trix-editor-typography {
  // use to make typography across site similar
  line-height: 1.5;
  * {
    box-sizing: border-box;
  }
  h1 {
    font-size: 1.2em;
    margin: 0;
  }
  blockquote {
    margin: 0 0 0 0.3em;
    padding: 0 0 0 0.6em;
    border-left: 0.3em solid #ccc;
  }
  pre {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-family: monospace;
    font-size: 0.95em;
    margin: 0;
    padding: 0.5em;
    white-space: pre;
    background-color: #f5f5f5;
    overflow-x: auto;
    color: #333333;
    border: 1px solid #ccc;
  }
  ul, ol, li {
    margin: 0;
    padding: 0;
  }
  ul li, ol li, li li {
    margin-left: 1.8em;
  }
  img {
    max-width: 100%;
    height: auto;
  }

  > *:last-child {
    margin-bottom: 0;
  }
  // in case it is used on parent element
  trix-editor > *:last-child{
    margin-bottom: 0;
  }
}
