// common variables
$brand-danger: #d5322f; // red
$brand-success: #45ead2; // green
$brand-primary: #0071b6; // blue
$brand-warning: #855400;
$brand-error: #ba1a1a;
$sj-success-compliant: #00829b;

// used in gradients with brand-color
$brand-danger-light: #f63237;
$brand-success-light: #70e4cc;
$brand-warning-light: #fee101;

// brand primary lightened by 10%
$brand-primary-light: lighten($brand-primary, 10%);

$sj-black: #2b2922;
$sj-black-v2: #171c22;
$sj-terminal: #282a36;
$sj-monaco-terminal: #1e1e1e;
$sj-terminal-border: #ec66e0;
$sj-black-hover: darken($sj-black, 10%);
$sj-blue: #00629f;
$sj-green: #37d387;
$sj-dark-green: #006d30;
$sj-dark-blue: #194e7b;
$sj-gray-color: #76777a;
$sj-gray-color-second: #a7abb3;
$sj-light-gray-color: #c2c7cf;
$disabled-color: #8c9199;

$sj-deep-purple: #6642b5;

$sj-off-white: #fcfcff;
$sj-greyish: #5a5f66;
$sj-yellow: #fff4c7;
$sj-orange: #fee101;

$dark-bg-color: #42444a;
$gray-bg-collor: #868891;
$gray-darken: darken($gray-bg-collor, 20%);
$gray-border-color: #b6b8be;
$bg-primary-color: #012346;

$black: #000000;
$white: #ffffff;
$light-grey: #eeeeee;
$mild-grey: #cccccc;

// header
$header-padding-y: 1.5rem;
$header-height: 60px;

// subheader
$subheader-padding-y: 1rem;
$subheader-bg-color: $dark-bg-color;

$answers-gray: #868892;

// tabs
$tabs-font-size: 1.5rem;

$inner-tabs-bg: #e7e7e9;
$inner-tabs-bg-active: #cfcfd3;
$inner-tabs-color-active: #2b2922;

// images
$image-border-color: #5e686b;

// borders
$border-light: rgba(0, 0, 0, 0.2);
$border-gray: rgba(0, 0, 0, 0.4);
$table-gray-border: #e0e0e0;
$color-secondary: #e7e7e9;
$color-secondary-darker: #d3d3d3;
$color-fifth: #424546;
//

$xs-max-width: 565px;

// Trix editor
$trix-content-padding-x: 0.6em;

// border-radius
$border-radius: 5px;
$sharp-border-radius: 2px;

// fonts
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-smallest: 10px;

// learn
$learn-footer-tab-height: 55px;

//menu
$menu-triangle-border: 2px solid #cbd6e3;

//new-modal
$modal-header-background: #d5dbe8;
$modal-backdrop-background: #0e2f52;
$modal-font-color: #092346;
$modal-padding-top: 35px;
$modal-padding-bottom: 25px;
$modal-success-color: #216d59;
$destroy-button-background: #cf432a;
$selected-background: #9be6fb;
$modal-text-link-color: #64656e;
$modal-close-background-color: #45bee0;

//new-guide
$guide-top: 100px;
$guide-height: 38px;
$guide-context-top: 15px;
$in-progress-color: #6642b5;
$completed-color: #006d30;
$not-started-color: #00687c;
$optional-color: #635f7b;

$default-legend-font-color: #ffffff;
$optional-legend-font-color: #00687c;
$failed-legend-font-color: #092346;

//full_catalog
$full_catalog-filter-width: 300px;
$full_catalog-filter-width-small: 200px;
$full_catalog-borders-color: #90919a;
$full-catalog-content-tournament-only-color: #365ca8;
$full-catalog-content-path-only-color: #006d30;

//journal
$root-padding: 15px 0;
$horizontal-padding: 10px;
$item-padding: 10px 20px;

//new-learn-page
$learn-header-margin-bottom: 25px;

//experiments
$min-height-experiment-logs: 75px;
$border-radius-experiment-logs: 5px;

//learn
$learn-background: #17466f;
$learn-feature-tour-height: 50000px;
$divider-margin: 12px;
$active-learn-lesson: #8c9199;
$inactive-learn-lesson: #184385;
$list-view-trigger-icon-size: 24px;
$learn-panel-background: #dee3eb;

// focus-outline
$focus-outline: #fff solid 2px;

//tournaments
$panel-width: 350px;
$panel-padding: 12px;

//emails
$email-primary-color: #009845;

//breakfix-content
$breakfix-primary-green: #006d30;
$sandbox-output-header: #dee3eb;

$breakfix-main-color: #006d30;
$breakfix-border-color: #76777a;
$breakfix-btn-font-size: 12px;
$breakfix-btn-padding: 4px 18px;
$breakfix-btn-radius: 5px;
$breakfix-btn-margin: 0 0.5em;

$breakfix-content-offset: 280px;
$browser-text-area-bg: rgb(51, 51, 51);
$browser-text-color: rgb(238, 238, 238);
$browser-text-area-padding: 15px 15px 75px;

// Carousel
$carousel-active-btn: #42474e;

$modal-z-index: 2;

// Shadows & Effects
$default-box-shadow:
  0px 4px 8px 3px rgba(0, 0, 0, 0.15),
  0px 1px 3px 0px rgba(0, 0, 0, 0.3);
$light-box-shadow:
  0px 1px 2px 0px rgba(0, 0, 0, 0.3),
  0px 1px 3px 1px rgba(0, 0, 0, 0.15);
$heavy-box-shadow:
  0px 8px 12px 6px rgba(0, 0, 0, 0.15),
  0px 4px 4px 0px rgba(0, 0, 0, 0.3);
