// light
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-300.eot");
  src:
    local("☺"),
    url("../fonts/Nunito-300.woff") format("woff"),
    url("../fonts/Nunito-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

// book
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-400.eot");
  src:
    local("☺"),
    url("../fonts/Nunito-400.woff") format("woff"),
    url("../fonts/Nunito-400.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// medium
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-600.eot");
  src:
    local("☺"),
    url("../fonts/Nunito-600.woff") format("woff"),
    url("../fonts/Nunito-600.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

// heavy
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-700.eot");
  src:
    local("☺"),
    url("../fonts/Nunito-700.woff") format("woff"),
    url("../fonts/Nunito-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// black
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito-900.eot");
  src:
    local("☺"),
    url("../fonts/Nunito-900.woff") format("woff"),
    url("../fonts/Nunito-900.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

// Nunito extra bold
@font-face {
  font-family: "NunitoExtraBold";
  src: url("../fonts/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

// termina font

@font-face {
  font-family: Termina;
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Termina-Thin.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/Termina-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Termina-Light.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Termina-Regular.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Termina-Medium.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Termina-Demi.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Termina-Bold.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Termina-Heavy.otf") format("opentype");
}

@font-face {
  font-family: Termina;
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Termina-Black.otf") format("opentype");
}

// INTER FONT

// light
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// bold
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// Roboto Thin
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

// Roboto Thin Italic
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

// Roboto Light
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

// Roboto Light Italic
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

// Roboto Regular
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

// Roboto Italic
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

// Roboto Medium
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

// Roboto Medium Italic
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

// Roboto Bold
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// Roboto Bold Italic
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

// Roboto Black
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

// Roboto Black Italic
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
