$nav-header-height: 66px;

.errorPage {
  background-color: #e7e7e9;
  font-family: "Avenir";
  min-height: calc(100vh - $nav-header-height);

  .navbarContainer {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
  }

  .navbar {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0.4rem 1rem;
    width: 100%;
    z-index: 5;
    height: $nav-header-height;
  }

  .navLink {
    color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: rgba(0, 0, 0, 0.7);
      text-decoration: none;
    }
  }

  .nav {
    list-style-type: none;
    padding: 0;
    width: 40%;
  }

  .navbarBrand {
    margin-right: 0;
    padding: 0;
    text-align: center;
    width: 20%;

    img {
      display: inline-block;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      width: 100px;
    }
  }

  .navItem {
    text-align: center;
    width: 50%;
  }

  .resourceItem {
    width: 40%;
  }
  .loginItem {
    width: 60%;
  }

  .login {
    float: left;
    font-weight: 700;
    margin-right: -0.4rem;
    text-transform: uppercase;
  }
  .schedule {
    background-color: #ffc107;
    border-color: #ffc107;
    border-radius: 10px;
    color: #212529;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    padding: 0.75rem 0.5rem;
    text-transform: uppercase;

    &.returnHome {
      font-size: 1rem;
      margin-top: 1.5rem;
    }
  }

  .container {
    margin-top: $nav-header-height;
  }

  .content {
    padding: 50px 0;
    text-align: center;

    .stop {
      max-width: 550px;
      width: 100%;
    }

    h1 {
      color: #000;
      font-size: 2em;
      font-weight: 500;
      line-height: 1.3em;
      max-width: 700px;
      margin: 1.2em auto;
    }

    p {
      font-size: 1.4em;
      color: #000;
      line-height: 1.3em;
      margin: 0.8em auto;
      max-width: 600px;
    }

    .dialog {
      background: #cfcfd3;
      border-radius: 10px;
      width: 95%;
      max-width: 33em;
      margin: 4em auto;
      padding: 2em;
    }
  }
}
