@import "../styles/variables";

.modal-confirmation {
  margin-top: 15%;

  .modal-body {
    padding-top: 3em;
  }
  .modal-footer {
    overflow: hidden;
    padding: 0;
    border-radius: 0;
    border: none;

    .btn {
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 1em;
      width: 50%;

      &.btn-default {
        background: $color-secondary;
      }
    }
  }

  .modal-icon {
    background: $brand-danger;
    border: 8px solid $color-secondary;
    border-radius: 50%;
    color: $color-secondary;
    display: inline-block;
    font-size: 2.4em;
    font-style: normal;
    font-weight: 500;
    height: 70px;
    left: 50%;
    margin-left: -35px;
    position: absolute;
    text-align: center;
    top: -35px;
    width: 70px;

    &::after {
      content: "!";
    }
  }
}

.modal-content {
  border-radius: $sharp-border-radius;
}
